<template>
  <div class="subtitle" id="subtitle">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SubTitle'
}
</script>

<style scoped>
  .subtitle {
    font-size: var(--h3-size);
    font-weight: 700;
  }
</style>
