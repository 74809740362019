<template>
  <div class="title" id="title">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PageTitle'
}
</script>

<style scoped>
  .title {
    font-size: var(--h1-size);
    font-weight: 700;
  }
</style>
