<template>
  <div class="preview-canvas-wrapper" :class="{'is-ios': this.$store.state.isDeviceIos}">
    <div class="preview-thumbnail">
      <img :src="imgPathSet[showStatus]" :class="{'gif': nowPhase(0)}" alt="preview"/>
    </div>
    <div class="select-wrapper">
      <div class="preview-select" @click="toggleStatus(0)" :class="{'selected': nowPhase(0)}">
        <img :src="imgPathSet[0]" alt="preview"/>
      </div>
      <div class="preview-select" @click="toggleStatus(1)" :class="{'selected': nowPhase(1)}">
        <img :src="imgPathSet[1]" alt="preview"/>
      </div>
      <div class="preview-select" @click="toggleStatus(2)" :class="{'selected': nowPhase(2)}">
        <img :src="imgPathSet[2]" alt="preview"/>
      </div>
      <div class="preview-select" @click="toggleStatus(3)" :class="{'selected': nowPhase(3)}">
        <img :src="imgPathSet[3]" alt="preview"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewCanvas',
  props: ['pfName'],
  data () {
    let imgPathSet = ['', '', '', '']
    return {
      showStatus: 0,
      imgPathSet
    }
  },
  mounted () {
    this.imgPathSet = [
      require('../../assets/portfolio/' + this.pfName + '/0.gif'),
      require('../../assets/portfolio/' + this.pfName + '/1.png'),
      require('../../assets/portfolio/' + this.pfName + '/2.png'),
      require('../../assets/portfolio/' + this.pfName + '/3.png')
    ]
  },
  methods: {
    toggleStatus (n) {
      this.showStatus = n
    },
    nowPhase (n) {
      return this.showStatus === n
    }
  }
}
</script>

<style scoped>
  .preview-canvas-wrapper {
    width: 400px;
    margin-right: 20px;
    padding-top: 16px;
  }

  .preview-canvas-wrapper.is-ios {
    padding-top: 0;
    padding-bottom: 15px;
  }


  .preview-thumbnail > img {
    width: 400px;
  }
  .preview-thumbnail > img.gif {
    width: 440px;
  }

  .preview-thumbnail {
    height: 200px;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
  }

  .select-wrapper {
    padding-top: 20px;
    height: 50px;
  }

  .preview-select.selected > img,
  .preview-select > img:hover {
    opacity: 1;
  }
  .preview-select > img {
    height: 50px;
    cursor: pointer;
    opacity: .75;
    transition: .25s ease-in-out;
  }

  .preview-select.selected {
    border: 1px solid #e9e9e9;
  }
  .preview-select {
    display: inline-block;
    width: 101px;
    height: 50px;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid transparent;
    margin-right: -1px;
  }
</style>
