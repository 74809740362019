// ListStagger.js
let ListStagger = {
  data () {
    return {
      animationDelay: 0
    }
  },
  mounted () {
    this.animationDelay = this.$store.state.prevPhase === 0 ? 1800 : 400
    this.$anime({
      targets: '.stagger-list',
      opacity: [0, 1],
      easing: 'spring(1, 70, 10, 0)',
      // easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: 300,
      delay: this.$anime.stagger(100, { start: this.animationDelay }) // increase delay by 100ms for each elements.
    });
  }
};
export default ListStagger;
