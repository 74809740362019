<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        ArtiFriends Homepage (<a href="https://www.artifriends.com/" target="_blank">artifriends.com</a>
        *라이브 버전과는 차이가 있음)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2019. 07. ~ 2019. 09.<br/>
        사용 프레임워크: Vue 2(CSR)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="af-hp"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          Vue.js 기반 <strong>횡스크롤 인터랙션 페이지</strong>인 회사 홈페이지를 개발하였습니다. 다양한 애니메이션 오브제가 생성되고 제거되는
          디자인적 기획의 구현을 위해 컴포넌트 라이프사이클을 꼼꼼히 살피고 최적화하였습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          Vue 2, Vuex, CSS Animation(Keyframe)
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          애니메이션, 뷰 컴포넌트 라이프사이클, 이벤트 핸들링, 반응형
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'AFHomepage',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>

