<template>
  <div>
    <div class="stagger-list timeline-label">
      <span class="">- Main Skill Set ({{listData.title}})</span>
    </div>
    <div class="skillset-table">
      <div class="stagger-list skillset-table__row" v-for="(skill, skillIndex) in listData.specs"
           :key="skillIndex">
        <div class="skillset-table__stack">
          <img :src="require('../../assets/skill_sets/' + skill.imgName + '.svg')" alt=""/>
        </div>
        <div class="skillset-table__star">
          {{skill.name}}<br/>
          {{skill.level}}
        </div>
        <div class="skillset-table__detail" v-html="skill.explanation">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationDelay from '../../atoms/AnimationDelay'
import ListStagger from '../../atoms/ListStagger'

export default {
  name: 'SkillsetListDesign',
  props: ['listData'],
  mixins: [AnimationDelay, ListStagger],
}
</script>

<style scoped>

  .timeline-label {
    padding: 0 0 4px;
    font-size: var(--h2-size);
    margin: 32px 0 12px;
  }

  .timeline-label > span {
    font-weight: 700;
    /*border-bottom: 1px solid var(--content-color);*/
  }

  .skillset-table__row {
    display: flex;
    width: 100%;
    font-size: var(--body-size);
    padding: 5px 0 12px;
  }

  .skillset-table__stack > img {
    width: 2.4em;
  }

  .skillset-table__stack {
    flex: none;
    width: 4.5em;
    padding: 0.3em 0.6em;
  }

  .skillset-table__star {
    flex: none;
    width: 100px;
    font-weight: 600;
    padding-right: 20px;
  }

  .skillset-table__detail {
    flex: 1;
  }
</style>
