<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        코드잇 메인 웹페이지 국제화 (영어: <a href="https://www.codeit.kr/" target="_blank">www.codeit.com</a>,
        인도네시아어:  <a href="https://www.codeit.kr/" target="_blank">id.code.it</a>)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2020. 07. ~ 2021. 07.<br/>
        사용 프레임워크: React(Next.js)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="codeitGlobalization"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          인도네시아 진출을 위한 인도네시아어 페이지와, 영미권 진출을 위한 영미권 페이지를 구현하였습니다. 지속 가능한 페이지의 확장과 번역 관리를 위하여
          <strong>언어 관리를 위한 CMS</strong>를 별도로 구축하고 <strong>배포 파이프라인과 연결</strong>하여 Continuous Integration을 구성하였습니다.
          해외 결제를 지원하기 위해 <strong>Stripe</strong>의 JavaScript 모듈을 가져와 결제를 구현하고 구글 OAuth도 확장하였습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          React, Next.js, MobX, Webpack, Babel, ESLint, TypeScript, SCSS, CircleCI, AWS
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          국가별 빌드 구성, OAuth, CMS 구축, 반응형 UI, 분기처리, 국가별 컴포넌트 관리, <strong>해외결제(Stripe)</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'CodeitGlobalization',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>

