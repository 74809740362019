<template>
  <div class="caption-box">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'CaptionBox'
}
</script>

<style scoped>
  .caption-box{
    font-size: 14px;
    color: #dddddd;
  }

  @media screen and (max-width: 1100px){
    .caption-box:after {
      content: '(* 너비 1100px 이하 화면에서는 이미지가 표시되지 않습니다.)';
    }
  }
</style>
