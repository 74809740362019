<template>
  <div class="content-wrapper">
    <PageTitle>
      <div class="title">Philosophy in Work</div>
    </PageTitle>
    <div class="content" id="content">
      <div class="philosophy-wrapper">
        <div class="philosophy-text stagger-list">
          <br/>
          <SubTitle>
            모든 개발물의 인과관계를 꼼꼼하게 이해하려고 노력합니다.
          </SubTitle>
          <br/>
          저의 전공은 전산학이 아닌 물리학이었습니다. 오랜 기간에 걸쳐 즐겁게 물리학을 공부하는 동안,
          원인과 결과의 상관관계를 엄밀하게 따져묻고 분해하여 증명하는 일은 학습은 물론 무언가를 이해하고 발전시키는 데 있어 가장 중요한 사고의 뿌리가 되었습니다.
          <br/><br/>
          자연스럽게 개발을 공부하면서도 웹 서비스의 전체적인 스키마에서부터 코드 한줄까지의 상호작용을 이해하려고 노력하는 습관을 갖게 되었습니다.
          가끔은 새로운 분야로의 지식적 확장을 더디게 하는 자체적인 러닝커브로 작용하기도 했지만,
          꼼꼼하게 학습한 새로운 개념은 새로운 개발물을 위한 든든한 받침판이 되어 주고 있습니다.
          <br/><br/>
          인과관계에 대한 고민은 단순히 코드에만 한정되는 것이 아닙니다.
          작성하는 한줄의 코드가 서비스의 어떤 부분이 되어 어떻게 사용자외 상호작용할지에 대해 즐겁고 생산적으로 고민하며 모두에게 더 나은 서비스를 만들기 위해서 노력합니다.
          <br/><br>
        </div>
        <div class="philosophy-text stagger-list">
          <SubTitle>
            당위성이 충분한 UX/UI 디자인을 바탕으로 소통하고 디벨롭합니다.
          </SubTitle>
          <br/>
          모든 작업에서 ‘이유’를 찾는 것은 쉬운 일이 아닙니다.
          아트웍의 경우에는 원인보다 결과가 우선하기도 하고, 훌륭하고 멋진 UX도 중간과정 없이 직관을 바탕으로 등장할 때가 많습니다.
          <br/><br/>
          하지만 UX/UI 디자이너로써의 역할은 예술이 아닌 디자인이라고 생각하고,
          디자이너의 직관에 의존해서 다른 사람과 상호작용하고 설득을 시도하는 것은 서로에게 어렵고 소모적인 일이라고 생각합니다.
          <br/><br/>
          서로의 비전문분야를 혼합하고 함께 무언가를 빚어내는 과정에서 ‘디자이너만 이해할 수 있는 무언가’를 근거로 삼는 것은
          협업하는 모두에게 커다란 비용으로 돌아옵니다.
          디자인에서 다수가 납득할 수 있는 정돈된 근거를 바탕으로, 논리적이고 건강한 소통을 통해 멋진 개발물을 완성하는 것을 목표로 하고 있습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../atoms/PageTitle'
import SubTitle from '../atoms/SubTitle'
import AnimationTitle from '../atoms/AnimationTitle'
import AnimationDelay from '../atoms/AnimationDelay'
import ListStagger from '../atoms/ListStagger'

export default {
  name: 'PhilosophyInWork',
  components: { SubTitle, PageTitle },
  mixins: [AnimationDelay, AnimationTitle, ListStagger]
}
</script>

<style scoped>
  .content-wrapper {
    font-family: 'Spoqa Han Sans', sans-serif;
    text-align: left;
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    padding: var(--top-bottom-padding) 15px 0 0;
  }

  .content {
    margin-top: 16px;
    display: flex;

    align-items: center;
    align-content: center;
  }

  .philosophy-wrapper {
    display: flex;
    flex-direction: column;
  }

  .philosophy-text:first-child {
    margin-right: 40px;
  }

  .philosophy-text {
    font-size: 14px;
    line-height: 1.4;
    flex: 1;
    max-width: 800px;
  }
</style>
