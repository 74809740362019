// ListStagger.js
let AnimationTitle = {
  mounted() {
    this.$anime({
      delay: this.animationDelay,
      targets: '.title',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: 400
    })
  }
};
export default AnimationTitle;
