// AnimationDelay.js
let AnimationDelay = {
  data () {
    return {
      animationDelay: 0
    }
  },
  mounted() {
    this.animationDelay = this.$store.state.prevPhase === 0 ? 1600 : 200
  }
};
export default AnimationDelay;
