<template>
  <div class="shifter-wrapper" :class="{'ios': isIOS}">
    <slot/>
    <button @click="before">
      <img alt="left" src="../assets/utility/left.png">
    </button>
    <button @click="after">
      <img alt="right" src="../assets/utility/right.png">
    </button>
  </div>
</template>

<script>
import AnimationDelay from './AnimationDelay'

export default {
  name: 'Shifter',
  mixins: [ AnimationDelay ],
  mounted () {
    this.$anime({
      targets: '.shifter-wrapper',
      translateY: [10, 0],
      opacity: [0, 1],
      duration: 800,
      delay: 600 + this.animationDelay,
    });
  },
  methods: {
    before () {
      this.$emit('before')
    },
    after () {
      this.$emit('after')
    }
  },
  computed: {
    isIOS(){
      return this.$store.state.isDeviceIos
    }
  }
}
</script>

<style scoped>
  .shifter-wrapper{
    padding-left: 4px;
    padding-right: 40px;
    width: 100%;
    text-align: right;
  }
  div {
    position: absolute;
    bottom: calc(var(--top-bottom-padding) + 40px);
    left: 0;
  }
  @media screen and (max-width: 1100px){
    div {
      bottom: calc(var(--top-bottom-padding) + 40px);
    }
    div.ios {
      bottom: calc(var(--top-bottom-padding) + 100px);
    }
  }
  button:first-child {
    margin: 0 -1px 0 12px;
    padding: 1px 2px 0 0;
  }
  button:last-child {
    padding: 1px 0 0 2px;
  }
  button:hover{
    background-color: rgba(255,255,255,0.2);
  }
  button {
    padding-top: 1px;
    transition: .125s;
    background-color: transparent;
    border: 1px solid var(--content-color);
    width: 42px;
    height: 42px;
    text-align: center;
    outline: none;
    cursor: pointer;
  }
  img{
    height: 16px;
  }
</style>
