<template>
  <div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle'
}
</script>

<style scoped>
  div {
    margin: 6px 0 2px;
    font-size: var(--h3-size);
    font-weight: 900;
  }
</style>
