<template>
  <div class="content-wrapper">
    <PageTitle>
      <div class="title">About</div>
    </PageTitle>
    <div class="content" id="content">
      <img alt="sunghwan" class="profile" src="../assets/sunghwan.png"/>
      <div class="content-text">
        <div class="stagger-list">
          안녕하세요. 신성환입니다.<br/>
        </div>
        <div class="stagger-list">
          2016년, 학문보다는 조금 더 창의적인 일에 스스로의 관심이 있음을 알게 되어<br/>
          오랫동안 공부하던 물리학은 책장에 남겨두고 무언가를 만들어내는 일에 발을 담그게 되었습니다.<br/>
        </div>
        <div class="stagger-list">
          다른 사람들이 했던 것들을 따라하는 것 보다는<br/>
          스스로 생각하고 오리지널리티를 가진 무언가를 만들고 정제하는 것을<br/>
          성장의 방식으로 삼고 있습니다.<br/>
        </div>
        <div class="stagger-list">
          <strong>React</strong>와 <strong>Vue.js</strong>를 모두 좋아하며
          <strong>Adobe 디자인툴</strong>을 활용한 디자인 및 개발을 좋아합니다.<br/>
          기능 구현은 물론 비즈니스 관점에서 서비스를 바라보고 임팩트를 만들어 내는 것과<br/>
          어플리케이션 전체의 인프라스트럭쳐를 고민하고 설계하는 것을 좋아합니다.<br/>
        </div>
        <div class="stagger-list">
          개발자의 작업은 유저를 만나 상호작용할 때 진정으로 가치를 얻는다고 믿습니다.<br/>
          제가 만들어내는 코드 조각들이 세상에 즐거움과 가치를 쌓아 나가길 기대하며 개발합니다.
          <br/>
        </div>
        <div class="stagger-list">
          지식을 잘 정돈해서 다시 나눕니다.
          개인 <a target="_blank" href="https://velog.io/@bluestragglr">기술 블로그</a>에 글을 쓰거나,
          <a target="_blank" href="https://velog.io/@bluestragglr">우아한형제들 기술블로그에</a>에 글을 투고합니다.<br/>
          <a target="_blank" href="https://www.youtube.com/live/9AUEfGZslmw?feature=share">
            유튜브 세미나
          </a>나
          <a target="_blank" href="https://woowacon.com/ko/2022/detailVideo/22">
            우아한 테크콘서트
          </a>같은 영상을 통해 지식을 나누기도 합니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../atoms/PageTitle'
import AnimationTitle from '../atoms/AnimationTitle'
import AnimationDelay from '../atoms/AnimationDelay'

export default {
  name: 'About',
  components: { PageTitle },
  mixins: [AnimationDelay, AnimationTitle],
  props: ['wasZero'],
  data () {
    return {
      paragraphCount: 4
    }
  },
  mounted () {
    this.$anime({
      delay: this.animationDelay + 200,
      targets: '.content',
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: 800
    })
    this.$anime({
      targets: '.stagger-list',
      opacity: [0, 1],
      duration: 3200,
      delay: this.$anime.stagger(100, { start: this.animationDelay + 400 }) // increase delay by 100ms for each elements.
    });
  }
}
</script>

<style scoped>
  .content-wrapper {
    font-family: 'Spoqa Han Sans', sans-serif;
    text-align: left;
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    padding: var(--top-bottom-padding) 15px 0 0;
  }

  .content {
    margin-top: 16px;
    display: flex;

    align-items: center;
    align-content: center;
  }

  .content-text {
    padding: 0 1.6em;
    font-size: 14px;
    flex: 1;
  }

  .profile {
    flex: none;
    width: 360px;
    height: 360px;
    max-width: 1280px;
    margin-right: auto;
  }
  @media screen and (max-width: 1200px){
    .profile {
      width: 210px;
      height: 210px;
    }
  }

  @media screen and (max-width: 1100px) {
    .profile {
      display: none;
    }
    .content-text {
      padding: 0 20px 0 0;
    }
  }
</style>
