<template>
  <div>
    <div v-if="phaseData.current === 0">
      <BiographyList/>
    </div>
    <div v-if="phaseData.current !== 0">
      <SkillsetList :list-data="skillSet[phaseData.current - 1]" :key="phaseData.current"/>
    </div>
    <Shifter @before="updatePhase(-1)" @after="updatePhase(1)">
      {{phaseData.current + 1}}/{{phaseData.length}}
    </Shifter>
  </div>
</template>

<script>
import Shifter from '../../atoms/Shifter'
import BiographyList from './BiographyList'
import SkillsetList from './SkillsetList'

export default {
  name: 'BiographySkillSetContent',
  components: { SkillsetList, Shifter, BiographyList },
  data () {
    return {
      phaseData: {
        current: 0,
        length: 4
      },
      skillSet: [
        {
          title: 'Development',
          specs: [
            {
              imgName: 'react',
              name: 'React', level: '★★★★★', explanation: `현재 현업에서 사용하며 가장 많은 시간을 함께 하고 있는 기술입니다.
              TypeScript, MobX, Babel, Webpack을 사용해 프로젝트를 구성하고 개발합니다.
              <a target="_blank" href="https://velog.io/@bluestragglr/%EC%A3%BC%EB%8B%88%EC%96%B4%EB%8F%84-%ED%95%A0-%EC%88%98-%EC%9E%88%EB%8A%94-Next.js-SEO-robots.txt%EC%99%80-sitemap.xml-%EC%9E%90%EB%8F%99-%EC%83%9D%EC%84%B1%ED%95%98%EA%B8%B0">
                SEO를 위한 Sitemap 생성 작업 자동화
              </a>나
              <a target="_blank" href="https://velog.io/@bluestragglr/%EC%9B%B9%ED%8E%98%EC%9D%B4%EC%A7%80-%EC%84%B1%EB%8A%A5-%EA%B0%9C%EC%84%A0%ED%95%98%EA%B8%B0-%EC%9D%B4%EB%AF%B8%EC%A7%80%EC%99%80-%EA%B7%B8%EB%9D%BC%EB%8D%B0%EC%9D%B4%EC%85%98">
                그래픽 성능 최적화
              </a> 등의 작업을 포함하여 단순히 만들어진 프로덕트를 유지하고 개선하는 것이 아니라,
               새로운 프로젝트를 설정하거나 성능상의 지표를 눈에 띄게 끌어올리는 등 다양한 경험을 가지고 있습니다.`
            },
            {
              imgName: 'vue',
              name: 'Vue.js', level: '★★★★☆', explanation: `공식문서 번역에 참여하며 지속적으로 개인 프로젝트를 진행할 만큼 애정이 있는 프레임워크입니다.
              배포 수준의 서비스에 필요한 기능의 대부분을 찾거나 만들어 구현할 수 있습니다.
              주요 기본 라이브러리인 Vue-router, Vuex(store), Axios 등을 사용해 배포를 진행한 경험이 다수 있으며
              Vue 2 공식 문서의
              <a target="_blank" href="https://kr.vuejs.org/v2/guide/components-props.html">Props</a>,
              <a target="_blank" href="https://kr.vuejs.org/v2/guide/components-custom-events.html">커스텀 이벤트</a> 전체 페이지 번역 및
              기타 페이지들 번역을 맡았으며, Vue 3 공식 문서의 컴포넌트 부분 및 기타 부분의 번역에 참여하고 있습니다.<br/>`
            },
            {
              imgName: 'aws',
              name: 'AWS', level: '★★★☆☆', explanation: `AWS EC2, S3, CloudFront, Route53, Lambda를 활용해 본 경험이 있습니다.
              <a target="_blank" href="https://velog.io/@bluestragglr/Vue.js%EC%99%80-AWS-Lambda-Nodemailer-%EB%A1%9C-%EC%9D%B4%EB%A9%94%EC%9D%BC-%EC%A0%84%EC%86%A1-%ED%8F%BC-%EB%A7%8C%EB%93%A4%EA%B8%B0">Lambda와 Nodemailer를 이용한 메일링 폼 개발</a>
              경험 및 서버리스 https 배포 경험 등이 있습니다.`
            },
            {
              imgName: 'python',
              name: 'Python', level: '★★☆☆☆', explanation: `알고리즘 풀이 및 데이터 시각화를 위해서 익숙하게 활용할 수 있습니다.
              Conda를 이용해 가상환경을 구축하고 Numpy등의 라이브러리를 이용해 데이터를 정제하여 시각화하는데 익숙합니다.`
            },
            {
              imgName: 'unity',
              name: 'Unity', level: '★★☆☆☆', explanation: `간단한 게임을 제작하여 데스크톱 및 앱(iOS)으로 빌드하여 구동해본 경험이 있습니다.`
            },
          ]
        }, {
          title: 'Design',
          specs: [
            {
              imgName: 'illustrator',
              name: 'Adobe Illustrator', level: '★★★★☆', explanation: `Illustrator를 이용해 벡터 그래픽을 제작하거나
              다른 벡터 이미지를 편집하여 웹페이지상에 필요한 아트웍 및 유틸리티 에셋을 자유롭게 제작할 수 있습니다.
              편집디자인 등 다른 분야의 경험이나, 툴 자체의 생산성 향상을 위한 매크로 기능 등을 활용할 수 있습니다.`
            },
            {
              imgName: 'xd',
              name: 'Adobe XD', level: '★★★☆☆', explanation: `주로 Illustrator의 보조 도구로써 활용하며
              원하는 작업을 검색을 통해 수행할 수 있습니다. GIF등의 스택 에셋 제작, 목업 제작, 카메라로 촬영된 RAW 파일 편집 등
              필요한 에셋을 만들어 활용해 본 경험이 있습니다.`
            },
            {
              imgName: 'photoshop',
              name: 'Adobe Photoshop', level: '★★☆☆☆', explanation: `주로 Illustrator의 보조 도구로써 활용하며
              원하는 작업을 검색을 통해 수행할 수 있습니다. GIF등의 스택 에셋 제작, 목업 제작, 카메라로 촬영된 RAW 파일 편집 등
              필요한 에셋을 만들어 활용해 본 경험이 있습니다.`
            },
            {
              imgName: 'premier',
              name: 'Adobe Premier', level: '★★☆☆☆', explanation: `인터뷰 영상의 제작이나 인스타그램 홍보용 숏 영상의 촬영 및 제작 경험이 있습니다.
              다만, 툴에 대한 숙련도가 부족하여 시간이 다소 소요되는 편입니다.`
            },
            {
              imgName: 'sketch',
              name: 'Sketch', level: '★★☆☆☆', explanation: `세 개 정도의 프로젝트를 스케치를 활용하여 진행한 적이 있습니다.
              프로젝트의 기간이 짧아 부득이하게 다양한 기능을 습득 및 활용하는 대신 기본 기능만을 이용하였기에 숙련도는 높지 않습니다.
              심볼 등 디자인시스템을 구축하기 위한 툴의 존재와 스케치-제플린 연동 등에 대한 기본 지식이 있습니다.`
            },
          ]
        }, {
          title: 'Cowork tool',
          specs: [
            {
              imgName: 'github',
              name: 'Github', level: '★★★★★', explanation: `깃허브를 이용한 프로젝트 전반적인 관리와 협업 구조 생성 및 관리에
              능숙합니다. 레포지토리 생성부터 브랜치 및 머지, 코드리뷰 관리 쳬계를 구축하고 프로세스를 관리하여 배포까지 연결한 경험이 있습니다.
              <a target="_blank" href="https://velog.io/@bluestragglr/Github-Action%EC%9C%BC%EB%A1%9C-%EB%B0%B0%ED%8F%AC-%EC%9E%90%EB%8F%99%ED%99%94%ED%95%98%EA%B8%B0">Github Actions를 이용한 AWS 배포 자동화 경험</a>이 있습니다.`
            },
            {
              imgName: 'notion',
              name: 'Notion', level: '★★★★☆', explanation: `문서를 정리하는 것을 좋아하며, 정제된 글을 바탕으로 하는 협업을 좋아합니다. 노션을 이용한 공간의 관리와
              트리거 버튼을 통한 생산성 증대 등 노션의 다양한 기능을 활용하여 함께 생산적으로, 즐겁게 일하는 것에 익숙합니다.`
            },
            {
              imgName: 'confluence',
              name: 'JIRA & Confluence', level: '★★★☆☆', explanation: `워크플로우 생성 및 관리, 팀단위 협업을 위한 기록 공간 생성,
              프로젝트 보드 커스터마이징 및 관리 등 2년 3개월정도의 경력동안 각 프로젝트들의 어드민으로 협업을 관리한 경험이 있습니다.`
            },
            {
              imgName: 'zeplin',
              name: 'Zeplin', level: '★★★☆☆', explanation: `디자이너의 입장에서 개발자의 협업을 위해서,
              그리고 개발자의 입장에서 디자이너와의 협업을 위해 활용해 본 경험이 모두 있습니다.`
            },
          ]
        }
      ]
    }
  },
  methods: {
    updatePhase (delta) {
      this.phaseData.current = (this.phaseData.current + delta) % this.phaseData.length
      if(this.phaseData.current === -1){
        this.phaseData.current += this.phaseData.length;
      }
    }
  }
}
</script>

<style scoped>

</style>
