<template>
  <div class="content-wrapper">
    <PageTitle>
      Biography & Skill set
    </PageTitle>
    <BiographySkillSetContent/>
  </div>
</template>

<script>
import PageTitle from '../atoms/PageTitle'
import BiographySkillSetContent from './biography_skillset/BiographySkillSetContent'
import AnimationTitle from '../atoms/AnimationTitle'
import AnimationDelay from '../atoms/AnimationDelay'

export default {
  name: 'BiographySkillSet',
  components: { BiographySkillSetContent, PageTitle },
  mixins: [ AnimationDelay, AnimationTitle ],
  mounted () {
    this.animationDelay = this.$store.state.prevPhase === 0 ? 1600 : 200
    this.$anime({
      delay: this.animationDelay,
      targets: '.title',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: 400
    })
  },
}
</script>

<style scoped>
  .content-wrapper {
    font-family: 'Spoqa Han Sans', sans-serif;
    text-align: left;
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    flex: 1;
    padding: var(--top-bottom-padding) 40px 0 0;
    position: relative;
  }
</style>
