<template>
  <div id="app">
    <div class="main-content">
      <router-view/>
    </div>
    <div class="mobile-notification">
      <MobileNotification/>
    </div>
  </div>
</template>

<script>
import MobileNotification from './views/MobileNotification'

export default {
  components: { MobileNotification },
  mounted () {
    const isDeviceIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    this.$store.commit('initializeDevice', isDeviceIOS)
  }
}
</script>
<style>

  html {
    background-color: var(--space-black);
  }

  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700&display=swap');
  @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
  :root {
    --space-black: #191d23;
    --content-color: #ffffff;
    --orange-giant: #f3a77e;
    --blue-stragglr: #aee0ee;

    --h1-size: 32px;
    --h2-size: 24px;
    --h3-size: 18px;
    --body-size: 13px;
    --caption-size: 11px;

    --top-bottom-padding: 100px
  }

  @media (max-width: 1280px) {
    :root {
      --top-bottom-padding: 60px
    }
  }

  * {
    box-sizing: border-box;
    letter-spacing: .2px;
  }

  body {
    margin: 0;
  }
  strong {
    font-weight: 700;
    color: var(--orange-giant);
  }

  #app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--content-color);
    letter-spacing: 2px;
    line-height: 1.6;
    height: 100vh;
    width: 100vw;
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
  }

  a {
    font-weight: 700;
    color: var(--blue-stragglr);
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
  }

  .main-content {
    width: 100%;
  }

  @media (max-height: 640px), (max-width: 1023px), (max-height: 768px) and (max-width: 1024px) {
    .main-content {
      display: none;
    }
  }

  @media (min-height: 640px) and (min-width: 1024px) {
    .mobile-notification {
      display: none;
    }
  }
  @media (max-height: 768px) and (max-width: 1024px) {
    .mobile-notification {
      display: inherit;
    }
  }
</style>
