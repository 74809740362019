<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        코드잇 블로그 (<a href="https://blog.codeit.kr/" target="_blank">blog.codeit.kr</a>)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2021. 03. ~ 2021. 04.<br/>
        사용 프레임워크: React(Next.js)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="codeitBlog"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          주로 마케팅 관련 포스트들이 게시되는 코드잇 블로그를 개발하였습니다. <strong>Chrome Lighthouse 기준 SEO 점수 100점</strong>을 목표로 성능과 SEO 측면에서
          다양한 시도와 자동화를 진행하였습니다. 개발자가 아닌 관리자가 직접 게시물을 작성할 때 <strong>여러 가지 기능들(ex. 이미지에 바로 도달하는 랜딩 링크 자동 생성, Sitemap 갱신 및 서치콘솔 업데이트)</strong>을 개발하였습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          React, Next.js, MobX, Webpack, Babel, ESLint, TypeScript, SCSS, CircleCI, AWS
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          <strong>SEO</strong>, 자동화, Quill Editor, 텍스트 에디터 커스터마이징, UTM
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'CodeitBlog',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>

