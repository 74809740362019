<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        SeeSo Landing Page (<a href="https://seeso.io/" target="_blank">seeso.io</a>)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2020. 02. ~ 2020. 04.<br/>
        사용 프레임워크: Vue.js(CSR)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="ss-ld"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          시선추적 SDK를 소개하는 <strong>랜딩페이지</strong>를 <strong>기획, 디자인, 개발, 배포</strong>하였습니다.
          정적일 수 있는 소개 내용을 좀 더 인터랙티브하게 보이게 하기 위해 스크롤에 반응하는 애니메이션 등을 기획하고 구현하였습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          Vue 2, Node.js, nodemailer, AWS S3, AWS Lambda
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          AOS(Action on Scroll), 함수형 메일 폼,
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'SeeSoLanding',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>
