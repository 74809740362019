import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDeviceIos: false,
    prevPhase: 0
  },
  mutations: {
    initializeDevice: (state, payload) => {
      state.isDeviceIos = payload
    },
    updatePrevPhase: (state, payload) => {
      state.prevPhase = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
