<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        SeeSo Console Page (<a href="https://console.seeso.io/" target="_blank">console.seeso.io</a>)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2020. 01. ~ 2020. 03.<br/>
        사용 프레임워크: Vue.js(CSR)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="ss-cs"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          시선추적 유료 SDK를 개발자가 결제하고 사용할 수 있도록 하는 <strong>콘솔</strong>을 <strong>기획, 디자인, 개발</strong>하였습니다.
          <strong>로고 제작</strong>과 같은 브랜딩에서 시작하여 서비스 전체의 기획, UIUX 구성, 등 디자인 과정에서부터 프론트엔드 개발까지를 주도적으로 진행하였습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          Vue, Vuex, Axios, Anime.js
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          코드리뷰, 커스텀 이벤트, Props 고급 활용, OAuth, Github Actions
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'SeeSoConsole',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>
