<template>
  <div>
    <div class="blue-stragglr">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 196.61" id="blueStragglr_Objet">
        <title>blueStragglr</title>
        <g id="layer1">
          <g id="layer2">
            <circle class="orange" cx="85.36" cy="85.36" r="84.86"/>
            <circle class="blue" cx="110.4" cy="107" r="89.1"/>
          </g>
        </g>
      </svg>
    </div>
    <div>
      <div class="title">
        blueStragglr<br/>
      </div>
      <div class="about">
        KAIST Physics 13'<br/>
        Web Front-end Dev. / UXUI Designer
      </div>
      <div class="mobile-caption">
        찾아와 주셔서 감사합니다 🥰<br/>
        제 포트폴리오는 1024+ / 640+ 화면에<br/>
        최적화되어 있습니다.
      </div>


      <a href="https://github.com/blueStragglr">
        Github
      </a> &
      <a href="https://velog.io/@bluestragglr">
        Velog
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileNotification',
  mounted () {
    this.$anime({
      targets: '#blueStragglr_Objet',
      rotate: '360deg',
      duration: 10000,
      easing: 'linear',
      loop: true,
    })
  }
}
</script>

<style scoped>
  .title{
    font-weight: 700;
    font-size: 16px;
  }
  .about{
    font-size: 14px;
    margin: 20px 0;
  }

  .orange {
    stroke: #f3a77e;
    fill: none;
  }

  .blue {
    stroke: #aee0ee;
    fill: none;
  }

  .blue-stragglr {
    margin: -60px auto 30px ;
    width: 200px;
    height: 200px;
    transition: top 1.6s ease-in, left 1.3s ease-in-out, right 1.6s ease-in-out, width 1s linear, height 1s linear;
  }


  .mobile-caption{
    font-size: var(--body-size);
  }
  a {
    font-size: var(--body-size);

  }
</style>
