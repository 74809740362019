<template>
  <div class="content-wrapper">
    <PageTitle>
      Portfolio
    </PageTitle>
    <div v-if="phaseData.current === 0">
      <CodeitMain/>
    </div>
    <div v-if="phaseData.current === 1">
      <CodeitBlog/>
    </div>
    <div v-if="phaseData.current === 2">
      <CodeitGlobalization/>
    </div>
    <div v-if="phaseData.current === 3">
      <AFHomepage/>
    </div>
    <div v-if="phaseData.current === 4">
      <SeeSoConsole/>
    </div>
    <div v-if="phaseData.current === 5">
      <TaxCalc/>
    </div>
    <Shifter @before="updatePhase(-1)" @after="updatePhase(1)">
      {{phaseData.current + 1}}/{{phaseData.length}}
    </Shifter>
  </div>
</template>

<script>
import PageTitle from '../atoms/PageTitle'
import BiographyList from './biography_skillset/BiographyList'
import Shifter from '../atoms/Shifter'
import SeeSoLanding from './portfolio/SeeSoLanding'
import AFHomepage from './portfolio/AFHomepage'
import SeeSoConsole from './portfolio/SeeSoConsole'
import TaxCalc from './portfolio/TaxCalc'
import AnimationTitle from '../atoms/AnimationTitle'
import AnimationDelay from '../atoms/AnimationDelay'
import CodeitMain from './portfolio/CodeitMain'
import CodeitBlog from './portfolio/CodeitBlog'
import CodeitGlobalization from './portfolio/CodeitGlobalization'

export default {
  name: 'PortFolio',
  components: { CodeitGlobalization, CodeitBlog, CodeitMain, TaxCalc, SeeSoConsole, AFHomepage, SeeSoLanding, Shifter, BiographyList, PageTitle },
  mixins: [AnimationDelay, AnimationTitle],
  data () {
    return {
      phaseData: {
        current: 0,
        length: 6
      },
    }
  },
  methods: {
    updatePhase (delta) {
      this.phaseData.current = (this.phaseData.current + delta) % this.phaseData.length
      if(this.phaseData.current === -1){
        this.phaseData.current += this.phaseData.length;
      }
    }
  }
}
</script>

<style scoped>
  .content-wrapper {
    font-family: 'Spoqa Han Sans', sans-serif;
    text-align: left;
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    padding: var(--top-bottom-padding) 40px 0 0;
    position: relative;
  }
</style>
