<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        임원퇴직금 세금 계산기 (현재 퍼블릭 접근 불가)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2019. 07 ~ 2019. 10.<br/>
        기술스택: Vue.js(CSR)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="txc"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          Vue.js를 이용해서 임원의 퇴직소득을 계산할 수 있는 프로그램을 외주 개발하였습니다. 그래프 라이브러리 등 외부 라이브러리를 다수 이용하여
          <strong>서버리스 환경에서 입력을 받아 세금을 계산하고 시각자료를 PDF로 출력</strong>할 수 있도록 구현하였습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          Vue 2, Vuex, HTML2CANVAS, jsPDF, Waavi, chart.js
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          서버리스, Vuex, <strong>데이터 시각화</strong>, 인쇄, 크로스브라우징
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'TaxCalc',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>
