<template>
  <div>
    <div class="stagger-list">
      <SubTitle>
        코드잇 메인 웹페이지 (<a href="https://www.codeit.kr/" target="_blank">www.codeit.kr</a>)
      </SubTitle>
    </div>
    <div class="stagger-list">
      <CaptionBox>
        작업시기: 2020. 07. ~ 2021. 07.<br/>
        사용 프레임워크: React(Next.js)<br/>
      </CaptionBox>
    </div>
    <div class="pf-content-wrapper">
      <div class="prev-canvas stagger-list">
        <PreviewCanvas pf-name="codeitMain"/>
      </div>
      <div class="text-content" :class="{'is-ios': this.$store.state.isDeviceIos}">
        <div class="stagger-list">
          <SectionTitle>
            프로젝트 요약
          </SectionTitle>
          실시간 접속자 수 200명 내외(초기에는 약 70명, 2021년 6월 기준 2~300명)의 코드잇 웹페이지를 유지보수하며 신규 기능들을 개발하며,
          성능상 개선을 위해 <strong>그래픽 최적화</strong>나 <strong>웹팩 트리셰이킹</strong> 등을 통해 기존 대비 로딩시간을 60% 이상
          감소시켰습니다. <strong>크로스브라우징</strong>을 위해 스타일시트 또한 대대적으로 리팩토링하거나 다시 구현하였습니다.<br/>
          안정적인 서비스를 위해 <strong>Jest를 이용하여 유닛 테스트</strong>를 작성하고, <strong>Puppeteer로 e2e 테스트</strong>를 구성하는 것을 시도하고 있습니다.
        </div>
        <div class="stagger-list">
          <SectionTitle>
            기술 키워드
          </SectionTitle>
          React, Next.js, MobX, Webpack, Babel, ESLint, TypeScript, SCSS, CircleCI, AWS, Jest, Puppeteer
        </div>
        <div class="stagger-list">
          <SectionTitle>
            작업 키워드
          </SectionTitle>
          크로스브라우징, 그래픽 최적화, 애니메이션, 번들링 최적화 (트리셰이킹), SEO 자동화, 페이지 개편, Unit Test, E2E Test
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../../atoms/SubTitle'
import CaptionBox from '../../atoms/CaptionBox'
import ListStagger from '../../atoms/ListStagger'
import SectionTitle from '../../atoms/SectionTitle'
import PreviewCanvas from './PreviewCanvas'

export default {
  mixins: [ListStagger],
  name: 'CodeitMain',
  components: { PreviewCanvas, SectionTitle, CaptionBox, SubTitle }
}
</script>

<style scoped>
  @import url("pf-content-css.css");
</style>

